.header {
  margin: 15px 0 10px;
}

.noUsers {
  text-align: center;
}

.subHeader {
  color: var(--darkGrey);
  font-weight: normal;
  margin-top: 0;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--lightGrey);
}

.join {
  width: 100%;
  display: block;
  padding: 20px 0;
  background-color: var(--lightGrey);
  border-radius: 9px;
  text-align: center;
  font-weight: bold;
  color: var(--brandColor) !important;
}

.viewRewardsLink {
  font-weight: bold !important;
}

.fundsRewards {
  margin-bottom: 16px;
  color: var(--brandColor);
}
