.container {
  margin-top: 40px;
}

.logout {
  margin-top: 30px;
}

.settingsHeading {
  color: var(--brandColor);
  margin-top: 30px;
}

.deleteAccount {
  margin-top: 30px;
  display: block;
  color: red;
}

.accordianHolder {
  margin-top: 50px;
}

.sorryToHear {
  margin-top: 0;
}

.feedback {
  font-weight: bold;
}

.stravaSignIn {
  font-weight: bold;
  font-size: 12px;
  display: block;
}

.openInNewIcon {
  position: relative;
  top: 1px;
  left: 2px;
}
