.loadingContainerFullScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
