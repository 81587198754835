.profileImageHolder {
  position: relative;
  margin: 20px auto;
  width: 120px;
}

.profileImage {
  border-radius: 50%;
  text-align: center;
  width: 120px;
  height: 120px;
  object-fit: cover;
  display: block;
}

.fallBackImage {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  flex-shrink: 0;
  text-transform: uppercase;
}

.input {
  display: none;
}

.button {
  background-color: var(--mediumGrey) !important;
  padding: 4px !important;
}

.messageContainer {
  text-align: center;
}

.iconContainer {
  position: absolute;
  right: 4px;
  bottom: 0;
}

.icon {
  color: black !important;
}
