.form {
  width: 100%;
}

.formControl {
  margin-bottom: 30px !important;
  display: block !important;
  width: 330px;
}

.cancelButton {
  margin-left: 10px !important;
}

.buttons {
  margin-top: 35px;
}

.settingsHeading {
  composes: settingsHeading from "./Settings.module.css";
}
