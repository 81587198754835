.container {
  font-size: 14px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: var(--lightGrey) 1px solid;
}

.timeframe {
  color: var(--darkGrey);
  margin-right: 4px;
}

.sortBy {
  color: var(--darkGrey);
  margin-right: 4px;
}

.infoButton {
  color: var(--brandColor);
  font-weight: normal;
}

.holder {
  display: flex;
  align-items: center;
}

.select {
  color: var(--brandColor);
  background-color: transparent;
  border: 0;
  appearance: none;
  font-weight: bold;
  outline: 0;
  font-size: 14px;
}

.selectCountries {
  composes: select;
  direction: rtl;
}

.sortLink {
  color: var(--brandColor);
  font-weight: bold;
}

.arrow {
  margin-top: 3px;
  color: var(--brandColor);
  font-size: 1.2em;
  margin-left: 3px;
}

.sortDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listHeading {
  font-weight: bold;
  font-size: 18px;
  display: block;
}
