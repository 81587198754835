.cardContainer {
  display: block;
  box-shadow: 1px 5px 15px 3px rgba(0, 0, 0, 10%);
  margin: 20px 0;
  border-radius: 9px;
  overflow: hidden;
  background-color: white;
  padding: 15px;
}

.companyImage {
  object-fit: cover;
  height: 50px;
  width: 50px;
  border-radius: 6px;
  margin-right: 10px;
}

.completed {
  color: var(--darkGrey);
}

.titleContainer {
  display: flex;
  width: 100%;
  padding-bottom: 12px;
}

.topTitle {
  font-weight: 500;
  display: block;
}

.howToTitle {
  margin-top: 0;
}

.codeHolder {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theCode {
  font-weight: bold;
  border: 1px var(--darkGrey) dotted;
  border-radius: 3px;
  padding: 2px 8px;
  font-size: 26px;
  margin-right: 5px;
}

.markdownHolder a,
.noReward a {
  font-weight: bold;
}
