.list {
  height: 200px;
}

.container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: var(--appWidth);
  border-top: 1px solid var(--lightGrey);
  z-index: 3;
}

.link {
  color: var(--mediumGrey);
}

.buttonMore {
  composes: link;
  font-weight: normal;
}

.text {
  font-size: 12px;
}

.sharedIconHeight {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* for dev */

  /* border: 0.1px solid black; */
}

.officeIcon {
  font-size: 21px;
}

.challengesIcon {
  font-size: 30px;
  transform: scale(1.1);
}

.leaderboardIcon {
  font-size: 30px;
}

.userIcon {
  font-size: 22px;
}

.moreIcon {
  font-size: 26px;
}

.settingsIcon {
  font-size: 25px;
  position: relative;
  left: 1px;
}

.feedbackIcon {
  font-size: 26px;
  position: relative;
  left: 1px;
  top: 2px;
}

.instagramIcon {
  font-size: 26px;
  position: relative;
  bottom: 1px;
  left: 1px;
}

.myRewardsIcon {
  font-size: 26px;
  position: relative;
  left: 1px;
}

.activeLink,
.activeLink svg {
  color: var(--brandColor);
}

/* widget */

.poweredByContainer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: var(--appWidth);
  background-color: white;
  border-top: 1px solid var(--lightGrey);
  padding: 4px var(--sidePadding) 6px;
  z-index: 3;
}

.poweredByLink {
  color: var(--darkGrey);
  float: right;
}

.logo {
  font-family: var(--brandFont);
  color: var(--brandColor);
  font-weight: bold;
  font-size: 1.4em;
}
