.contentBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  column-gap: 4%;
  margin: 22px 0;
}

.imageChild {
  border-radius: 30px 30px 0 0;
  width: 95%;
  max-width: 600px;
}

@media (min-width: 500px) {
  .imageChild {
    width: 90%;
  }
}

@media (min-width: 800px) {
  .imageChild {
    width: 70%;
  }
}

@media (min-width: 1000px) {
  .contentBlock {
    flex-direction: row;
    margin: 40px 0;
  }

  .imageChild {
    width: 48%;
  }

  .imageIsLeft {
    order: -1;
  }
}

.title {
  font-size: 1.9em;
}

.adminRegisterButton {
  color: white;
  border-color: white;
  text-decoration: none !important;
  display: table;
  margin: 10px 0;
}
