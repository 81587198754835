.container {
  display: flex;
  align-items: center;
  margin: 24px 0;
}

.position {
  margin-right: 3px;
  color: var(--darkGrey);
}

.imgShared {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 15px;
  flex-shrink: 0;
}

.fallBackImage {
  composes: imgShared;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
}

.meta ~ .meta {
  margin-left: 8px;
}

.image {
  composes: imgShared;
  object-fit: cover;
  margin-right: 15px;
}

.name {
  font-weight: bold;
}

.rightContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.topRight {
  display: flex;
  justify-content: space-between;
}

.bottomRight {
  display: flex;
  justify-content: space-between;
  color: var(--darkGrey);
  font-size: 12px;
  margin-top: 5px;
  align-items: baseline;
}

.treeIcon {
  position: relative;
  top: 1px;
  margin-right: 2px;
}

.cloudIcon {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.middleContent {
  flex-grow: 1;
}
