.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.themeWebsite {
  --borderActiveColor: white;
}

.themeAdmin {
  --borderActiveColor: black;
}

.heading {
  font-family: var(--brandFont);
  font-size: 5em;
  font-weight: bold;
}

.adminSubcopy {
  font-size: 18px;
}

.ul {
  list-style: none;
  display: none;
}

@media (min-width: 840px) {
  .heading {
    font-size: 6em;
  }

  .ul {
    display: flex;
  }

  .menu {
    display: none;
  }

  .link:hover,
  .link:focus {
    border-bottom: 3px solid var(--borderActiveColor) !important;
  }

  .activeLink {
    border-bottom: 3px solid var(--borderActiveColor) !important;
  }
}

@media (min-width: 1024px) {
  .ctaLink {
    composes: link;
    border-radius: 15px;
    border: solid 3px white !important;
    padding: 5px 10px !important;
  }
}

.menu {
  font-size: 18px;
  border-bottom: 3px solid var(--borderActiveColor);
}

.li {
  margin-left: 30px;
}

.link {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 3px;
  border-bottom: 3px solid transparent;
}

.link:hover,
.link:focus {
  border-bottom: 3px solid var(--brandColor);
}

.activeLink {
  border-bottom: 3px solid var(--brandColor);
}

.list {
  margin-top: 30px !important;
}
