.scrollableContainer {
  overflow-y: scroll;
  display: flex;
  margin: 21.44px calc(-1 * var(--sidePadding));
  padding: 0 var(--sidePadding);
}

.scrollableContainer::-webkit-scrollbar {
  display: none;
}

.scrollableContainer::after {
  content: "";
  width: var(--sidePadding);
  flex-shrink: 0;
}

.link {
  background-color: white;
  font-size: 14px;
  border-radius: 20px;
  border: 2px solid var(--mediumGrey);
  margin-right: 8px;
  padding: 5px 12px;
  align-items: center;
  display: flex;
  color: var(--mediumBlue);
}

.link:last-child {
  margin-right: 0;
}

.linkChecked {
  color: var(--brandColor);
  border-color: var(--brandColor);
  background-color: var(--brandColorOpacity);
}

.icon {
  position: relative;
  bottom: 1px;
}

.activityIcon {
  composes: icon;
  font-size: 17px;
  margin-right: 3px;
}

.activityIconCycling {
  composes: icon;
  font-size: 19px;
  margin-right: 5px;
  bottom: 0;
}

.activityIconCycling path {
  stroke: currentColor;
}
