.container {
  max-width: var(--appWidth);
  position: absolute;

  /* for the tracking bar */
  top: 22px;
}

.backButton {
  margin-top: 4px;
  outline: 0;
}

.backButton svg {
  font-size: 1.8em;
}
