@import-normalize;
@import "react-spring-bottom-sheet";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);
  outline: 0;
}

a:hover,
a:focus,
a:visited,
a:active {
  text-decoration: inherit;
}

button {
  outline: 0;
  padding: 0;
  border: 0;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-align: inherit;
  font-weight: bold;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);
}

* {
  box-sizing: border-box;
}
