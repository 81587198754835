.formControl {
  display: block !important;
  margin: 30px 0 !important;
}

.companySelect {
  width: 330px;
}

.officeSelect {
  width: 330px;
  max-width: 100%;
}

.create {
  font-weight: bold !important;
  color: var(--brandColor) !important;
}

.addIcon {
  margin-left: 5px;
  vertical-align: middle;
}

.companyUserCountRenderOption {
  font-style: italic;
  margin-left: 3px;
  display: inline-block;
  font-size: 14px;
  color: var(--darkGrey);
}

.officeUserCountRenderOption {
  font-style: italic;
  display: block;
  font-size: 14px;
  color: var(--darkGrey);
}
