.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
}

.emailInput {
  width: 320px;
}
