.container {
  padding: 20px;
}

.ul {
  list-style: none;
  text-align: center;
  color: white;
  margin: 15px;
  padding: 0;
}

.linksUl {
  composes: ul;
}

.li {
  display: inline;
}

.li + .li::before {
  margin: 10px;
  content: "•";
}

.socialUl {
  composes: ul;
  margin-top: 25px;
}

.socialUl li {
  display: inline;
  font-size: 1.8em;
  margin: 5px;
  color: white;
}
