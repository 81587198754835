.skelTop {
  margin-bottom: 6px;
}

.skelTopBottom {
  margin-bottom: 7px;
}

.cardContainer {
  display: block;
  box-shadow: 1px 5px 15px 3px rgba(0, 0, 0, 10%);
  margin: 20px 0;
  border-radius: 9px;
  overflow: hidden;
  background-color: white;
}

.card {
  padding: 12px;
}

@media (min-width: 390px) {
  .card {
    padding: 14px;
  }
}

@media (min-width: 420px) {
  .card {
    padding: 17px;
  }
}

.icon {
  color: var(--darkGrey);
  font-size: 1.2em;
}

.topContentContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 12px;
}

.bottomContentContainer {
  width: 100%;
  border-top: 1px solid var(--lightGrey);
  padding-top: 12px;
}

@media (min-width: 390px) {
  .topContentContainer {
    padding-bottom: 14px;
  }

  .bottomContentContainer {
    padding-top: 14px;
  }
}

.companyDetails {
  flex-grow: 1;
  min-width: 0;
}

.companyDetails > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.position {
  margin-right: 3px;
  color: var(--darkGrey);
}

.companyName {
  font-weight: 500;
}

.companyAddress {
  color: var(--darkGrey);
}

.image {
  object-fit: cover;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  flex-shrink: 0;
}

.fallBackImage {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  flex-shrink: 0;
}
