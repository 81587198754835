.container {
  margin: 20px 0;
  height: 350px;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 1px 5px 15px 3px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  color: white;
  background-size: cover;
  flex-grow: 1;

  --backingColor: 0, 0, 0;
}

.countryHolder {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.countryLabel {
  padding: 3px 10px;
  background-color: rgba(var(--backingColor), 0.3);
  border-bottom-left-radius: 5px;
}

.image {
  object-fit: cover;
  height: 58px;
  width: 58px;
  border-radius: 6px;
}

.bottomChild span {
  display: block;
}

.top {
  padding: 20px 10px 10px;
  display: flex;
  background-image: linear-gradient(
    rgba(var(--backingColor), 0) 0%,
    rgb(var(--backingColor), 0.7) 100%
  );
}

.topCopy {
  margin-left: 10px;
  align-self: flex-end;
}

.topCopy span:nth-child(2) {
  font-size: 14px;
}

.company {
  font-weight: bold;
  font-size: 18px;
  display: block;
  margin: 0;
}

.bottom {
  padding: 0 10px 10px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(var(--backingColor), 0.7);
  font-size: 14px;
  align-items: flex-end;
}

.bottomChild:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  padding-left: 5px;
}

.bottomChild span:nth-child(2) {
  font-size: 14px;
}

.label {
  color: var(--darkGrey);
}
