.container {
  margin-top: 50px;
}

.form {
  background-color: var(--backgroundColor);
  border-radius: 15px;
  padding: 20px;
}

.textField {
  display: block;
  margin: 20px 0;
}

.button {
  margin: 20px 0;
}
