.container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.td {
  width: 44px;
  height: 44px;
  position: relative;
  cursor: pointer;
}

.selectedDay {
  background-color: var(--brandColor);
  border-radius: 50%;
  color: white;
  font-weight: bold;
}

.ballHolder {
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.commuteBall {
  width: 6px;
  height: 6px;
  margin: 1px;
  border-radius: 50%;
  display: inline-block;
}

.commuteVehicleBall {
  composes: commuteBall;
  background-color: var(--mediumGrey);
}

.commuteGreenBall {
  composes: commuteBall;
  background-color: var(--brandColor);
}

.heatmapContainer {
  overflow-x: scroll;

  /* 
   to fix ios vertical scroll within this div and break page scroll,
   needed use to round number height px to stop it scroll a miniscule amount,
   rounding up from 112.45px (I think maybe caused by svg stuff)
  */
  height: 113px;
  direction: rtl;
}

.heatmapContainerInner {
  direction: ltr;
}

.heatmapContainer::-webkit-scrollbar {
  display: none;
}

.heatmapTile {
  stroke: transparent !important;
  fill: var(--lightGrey);
}

.hasACommute {
  composes: heatmapTile;
  fill: var(--brandColor);
}
