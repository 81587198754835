.activityIconContainer {
  font-size: 2em;
  margin-right: 10px;
  align-self: center;
}

.topContentWrapper {
  width: 100%;
}

.topContent {
  display: flex;
  width: 100%;
}

.largeChild {
  flex-grow: 1;
}

.bottomCopy {
  display: block;
}

.dateOfCommuteContainer {
  display: flex;
  justify-content: space-between;
}

.certaintySubtitle {
  display: block;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.certaintyStat {
  display: block;
}

.moreInfoDots {
  font-size: 1.2em;
}

.dev {
  margin-top: 20px;
}

.dev * {
  display: block;
}
