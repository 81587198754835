.tiersContainer {
  border-top: 5px solid white;
  padding: 20px 0;
  margin-top: 50px;
  background-color: #388e3c;
  border-radius: 6px;
}

.tiers {
  display: flex;
  flex-direction: column;
}

.child {
  padding: 0 20px;
  flex-grow: 1;
}

@media (min-width: 750px) {
  .tiers {
    flex-direction: row;
  }

  .child {
    width: 33%;
  }

  .child ~ .child {
    border-left: 1px solid white;
  }
}

.price {
  display: block;
  margin-bottom: 5px;
}

.perUserCopy {
  display: block;
}

.perOfficeCopy {
  display: block;
  margin-top: 5px;
}

.formControl {
  margin-top: 30px;
  min-width: 140px;
  color: white;
}

.ul {
  padding-left: 18px;
}

.toolTip {
  text-decoration: underline dotted;
}

.selectRoot::before {
  border-bottom-color: white !important;
}

.white {
  color: white;
}
