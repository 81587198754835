.buttonHolder {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.buttonGroup {
  margin-top: 2px;
}

.createButton {
  display: block !important;
}

.formControl {
  display: block !important;
  margin: 20px 0 !important;
}

.reason {
  display: block;
}

.link {
  font-weight: bold;
}

.copyAndButton {
  text-align: center;
}

.paper {
  margin: 10px !important;
}

.accordian {
  margin-bottom: 30px;
}
