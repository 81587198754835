.link {
  display: inline-block;
  font-size: 14px;
  border-radius: 20px;
  border: 2px solid var(--mediumGrey);
  margin-right: 8px;
  padding: 6px 12px;
  color: var(--mediumBlue);
  background-color: white;
}

.introCopy {
  padding-bottom: 17px;
}

.mailto {
  font-weight: bold;
}

.linkChecked {
  color: var(--brandColor);
  border-color: var(--brandColor);
  background-color: var(--brandColorOpacity);
}

.moreComing h3 {
  margin-top: 0;
}

.moreComing p {
  margin-bottom: 0;
}

.moreComing {
  border: 1px solid var(--mediumGrey);
  padding: 15px 10px;
  border-radius: 9px;
  background-color: var(--brandColorOpacity);
}

.signInCompany {
  font-weight: bold;
}

.register {
  font-weight: bold;
}
