.container {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.child {
  font-weight: inherit;
}

.manualCommuteContainer {
  justify-content: flex-start !important;
}

.manualCommuteContainer .child {
  margin-right: 10px;
}

.pace {
  display: none;
}

@media (min-width: 390px) {
  .pace {
    display: block;
  }
}

.statTitle {
  color: var(--darkGrey);
  font-size: 12px;
}

.statAmount {
  display: block;
  margin-bottom: 3px;
  font-weight: 500;
}

.cloudIcon {
  position: relative;
  top: 2px;
  right: 2px;
}

.treeIcon {
  position: relative;
  top: 1px;
  right: 2px;
}

.unit {
  font-size: 12px;
}
