.container {
  background-color: var(--brandColor);
  min-height: 100vh;
  padding-bottom: 130px;
}

.hero {
  padding: 10px 20px 50px;
  color: white;
  max-width: 1280px;
  margin: 0 auto;
}

@media (min-width: 500px) {
  .hero {
    padding: 10px 30px 50px;
  }
}

.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 1040px;
  column-gap: 70px;
  row-gap: 30px;
}

.routeWrapper a {
  text-decoration: underline;
}

@media (min-width: 948px) {
  .heroContainer {
    flex-direction: row;
  }

  .phoneHolder {
    margin-top: 20px !important;
    align-self: flex-start !important;
  }
}

.sections {
  margin: 50px 0;
}

.sections li {
  margin: 10px 0;
}

.contentFlexItem {
  flex-grow: 1;
}

.slogan {
  font-size: 1.8em;
  margin: 50px 0 30px;
}

.phoneHolder {
  display: none;
  flex-shrink: 0;
  margin: 0 0 150px;
  width: 100%;
  min-height: 1px;
  aspect-ratio: 171 / 350;
  align-self: center;
}

@media (min-width: 416px) {
  .phoneHolder {
    display: block;
  }
}

@media (min-width: 463px) {
  .phoneHolder {
    width: 420px;
  }
}

.image {
  margin-top: 40px;
  width: 100%;
}

@media (min-width: 463px) {
  .image {
    display: none;
  }
}

.smartPhone {
  overflow: hidden;
  height: 0;
  position: relative;
  padding-top: calc(350 / 171 * 100%);
}

.smartPhone::before {
  content: "";
  background-image: url("./phone-frame.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  pointer-events: none;
}

.iframe {
  border: 0;
  height: 91.6%;
  left: 6.5%;
  top: 2.8%;
  width: 86.6%;
  position: absolute;
  background: transparent;
  padding-top: 30px;
  background-color: var(--backgroundColor);
}

.joinUs {
  background-color: white;
  border-radius: 9px;
  padding: 10px 30px;
  color: var(--brandColor);
  text-decoration: none !important;

  /* font-weight: bold; */
  font-size: 1.3em;
  display: inline-block;
  margin-top: 20px;
}

.whosTalking {
  text-align: center;
  display: block;
}

.whosTalking a {
  font-weight: bold;
  text-decoration: underline;
}

.badgeSection {
  background-color: #6ec071;
  padding: 20px 0;
  margin: 35px 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.8px;
}

.logoHolders {
  padding: 20px 0 0;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.logoHolders a {
  margin: 20px;
}

.logoHolders img {
  width: 150px;
}

.subHeading {
  margin-top: 40px;
}

.brandFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
}

.brandImage {
  width: 80vw;
  flex-shrink: 0;
}

@media (min-width: 1110px) {
  .brandFlex {
    flex-direction: row;
  }

  .brandImage {
    width: 45%;
  }
}

.getStartedButton {
  font-weight: normal;
  text-decoration: underline;
}

.bottomLinks {
  text-align: center;
  font-size: 1.2em;
  display: block;
  margin-top: 30px;
}

.cyclingElectric {
  background-color: rgba(255, 255, 255, 30%);
}
