.image {
  width: 250px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.container {
  margin: 40px 0;
}

.memberContainer {
  margin-bottom: 50px;
}

.name {
  display: inline;
  margin-right: 10px;
  line-height: 1;
}

.dot {
  font-size: 0.8em;
  vertical-align: middle;
}

.profile {
  text-decoration: none !important;
}

.businessDetails {
  margin-top: 80px;
}
