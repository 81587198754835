.hero {
  height: 300px;
  margin-left: calc(var(--sidePadding) * -1);
  margin-right: calc(var(--sidePadding) * -1);
}

.topCopyHolder {
  display: flex;
  margin-top: 15px;
}

.companyImage {
  object-fit: cover;
  height: 58px;
  width: 58px;
  border-radius: 6px;
  margin-right: 10px;
  margin-top: 5px;
}

.header {
  margin: 0 0 5px;
}

.title {
  color: var(--darkGrey);
  display: block;
  margin-bottom: 15px;
}

.key {
  color: var(--darkGrey);
  display: block;
  margin-bottom: 3px;
}

.value {
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 16px;
  display: block;
}

.usersHolder {
  display: flex;
  overflow-y: scroll;
  margin-right: calc(var(--sidePadding) * -1);
  margin-top: 7px;
  margin-bottom: 16px;
}

.usersHolder::-webkit-scrollbar {
  display: none;
}

.joinChallengeButton {
  text-align: center;
  color: var(--brandColor);
  background-color: var(--brandColorOpacity);
  border-radius: 20px;
  border: 2px solid var(--brandColor);
  margin: 15px 0;
  width: 100%;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.joinChallengeButton[disabled] {
  background-color: var(--lightGrey);
}

.joinChallengeButton[disabled] span {
  margin-left: 5px;
}

.imgShared {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 15px;
  flex-shrink: 0;
}

.fallBackImage {
  composes: imgShared;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
}

.image {
  composes: imgShared;
  object-fit: cover;
  margin-right: 15px;
}

.link {
  composes: value;
  display: flex;
  align-items: center;
}

.link svg {
  margin-left: 3px;
}

.markdownDiv img {
  width: 100%;
}

.markdownDiv a {
  font-weight: bold;
}
