.header {
  text-align: center;
}

.commuteCount {
  font-weight: bold;
}

.notUserCopy {
  display: block;
  text-align: center;
}

.commutesToContainer {
  padding: 9px 0 7px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--lightGrey);
}

.commutesToLinks {
  display: flex;
  justify-content: space-between;
}

.commutesTo {
  font-weight: bold;
  font-size: 1.15em;
}

.seeAll {
  color: var(--brandColor) !important;
}

.link {
  font-weight: bold;
}

.progressContainer {
  margin: 20px 0;
}

.progressTitle {
  color: var(--darkGrey);
  font-size: 12px;
}

.yearInReview {
  text-align: center;
  width: calc(100% + 2 * var(--sidePadding));
  line-height: 44px;
  margin: 20px calc(-1 * var(--sidePadding));
  opacity: 0.8;
  color: var(--brandColor);
  font-family: var(--brandFont);
  font-weight: bold;
  font-size: 23px;
  background-color: var(--lightGrey);
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, green, pink, orange, red, blue, teal)
    1;
}
