.trackingBar {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0;
  text-transform: uppercase;
}

.isTrackingBar {
  composes: trackingBar;
  background-color: var(--brandColor);
  color: white;
}

.isNotTrackingBar {
  composes: trackingBar;
  background-color: #ffe500;
}
