:root {
  --brandColor: #4caf50;
  --brandColorOpacity: rgba(76, 175, 80, 5%);
  --brandFont: "Permanent Marker", cursive;
  --backgroundColor: #fafbfc;

  /* https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=4CAF50 */

  /* --brandColorLight: #80e27e;
  --brandColorDark: #087f23; */

  --darkGrey: #7e8594;
  --mediumGrey: #93a1bb;
  --lightGrey: #e6e9ed;
  --mediumBlue: #3c475b;
  --sidePadding: 14px;
  --appWidth: 441px;
  --footerHeight: 56px;
}

.container {
  max-width: var(--appWidth);
  margin: 0 auto;
}

.page {
  padding: 0 var(--sidePadding);
  padding-bottom: 90px;
}

@media (min-width: var(--appWidth)) {
  .container {
    width: var(--appWidth);
  }
}

.backButton {
  position: absolute;
  top: 8px;
  left: 10px;
  z-index: 1;
  outline: 0;
}

.backButton svg {
  font-size: 1.8em;
}

.leaderboardSelectContainer {
  position: relative;
  display: flex;
  margin: 22px 0;
}

.officesSelected {
  width: 140px;
}

.usersSelected {
  width: 123px;
}

.leaderboardSelect {
  display: block;
  background-color: transparent;
  border: 0;
  font-weight: bold;
  appearance: none;
  outline: 0;
  font-size: 2em;
  padding-right: 50px;
  color: inherit;
  padding-left: 0;
}

.chevronDown {
  color: var(--brandColor);
  position: absolute;
  font-size: 2em;
  top: 3px;
  user-select: none;
  pointer-events: none;
}

.officesChevron,
.usersChevron {
  composes: chevronDown;
  right: 4px;
}
