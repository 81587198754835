.basicContainer {
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;
  max-width: 360px;
}

.ballInputCheckbox {
  display: none;
}

.ballInputLabel {
  font-weight: 600;
  color: var(--mediumBlue);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.ballInputLabelChecked {
  color: white;
  background-color: var(--brandColor);
}

.fieldset {
  margin-top: 25px;
}

.timePickerHolder {
  display: flex;
  justify-content: center;
}

.learnMore {
  font-size: 12px;
}

.switchType {
  font-size: 12px;
  margin: 15px 0;
}

.switchIcon {
  position: relative;
  top: 4px;
  font-size: 16px;
}
