.ul {
  list-style: none;
  text-align: center;
  margin: 50px 0 30px;
  padding: 0;
}

.li {
  display: inline;
}

.li + .li::before {
  margin: 10px;
  content: "•";
}
