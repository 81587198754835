.mobileAppContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - var(--footerHeight));
}

.firebaseAuth {
  border-top: 1px solid var(--lightGrey);

  /* border-bottom: 1px solid var(--lightGrey); */
  padding: 20px 0;
}
