.section {
  font-size: 1.6em;
  font-family: var(--brandFont);
  text-align: center;
}

.section > span {
  display: block;
  margin: 6px 0;
}

.arrowHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  width: 100%;
}

.svg {
  width: 50px;
  height: 30px;
  margin-top: 3px;
  align-items: center;
}
