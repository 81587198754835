.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  outline: none;
}

.top {
  flex-grow: 1;
  background-color: var(--brandColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom {
  flex-shrink: 0;
  background-color: var(--backgroundColor);
  padding: 0 var(--sidePadding);
}

.logo {
  color: white;
  font-family: var(--brandFont);
  font-size: 5em;
  text-align: center;
  margin: 0;
}

.subtitle {
  font-size: 28px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  flex-direction: column;
}

@media (min-width: 385px) {
  .buttons {
    flex-direction: row;
  }

  .logo {
    font-size: 6.5em;
  }
}

.button {
  margin: 5px 0 !important;
}
