.header {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
}

.logoImageUrl {
  border-radius: 50%;
  text-align: center;
  margin: 20px auto;
  width: 64px;
  height: 64px;
  object-fit: cover;
  display: block;
}
