.keyFeatures {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.icon {
  font-size: 35px;
  margin-top: 19px;
  margin-right: 15px;
}

.aiIcon {
  composes: icon;
  width: 35px;
  height: 35px;
}

.feature {
  display: flex;
  border: 1px solid white;
  border-radius: 9px;
  padding: 5px 10px;
  background-color: var(--brandColor);
  margin: 15px 0;
}
